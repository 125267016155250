<template>
  <div class="watch-page">
    <section-panel class="watch-chart" title="睡眠 2023年11月1日 周一" v-for="(item, index) in data" :key="index">
      <chart-base :series="getSeries(item)" :color="color" :legend="legend"></chart-base>
    </section-panel>
    <section-panel class="grid-span-1" title="今日数据"></section-panel>
    <section-panel class="grid-span-2" title="睡眠分析"></section-panel>
    <section-panel class="grid-span-1" title="本周睡眠"></section-panel>
    <section-panel class="grid-span-2" title="本周睡眠统计"></section-panel>
    <section-panel class="grid-span-1" title="本月睡眠"></section-panel>
    <section-panel class="grid-span-2" title="本月睡眠统计"></section-panel>
  </div>
</template>

<script>
import SectionPanel from './section-panel';
import ChartBase from './chart-base';

export default {
	components: { SectionPanel, ChartBase },
	data() {
		return {
			color: ['#793ea4', '#41a6ee', '#f1bd3a', '#f97828'],
			legend: [
				{ name: '深睡时间', icon: 'triangle' },
				{ name: '浅睡时间', icon: 'triangle' },
				{ name: '快速眼动', icon: 'triangle' },
				{ name: '清醒时间', icon: 'triangle' },
			],
			data: [
				[
					{ data: [2, 0, 4, 6], name: '深睡时间' },
					{ data: [0, 3, 0, 0], name: '浅睡时间' },
					{ data: [0, 0, 0, 0], name: '快速眼动' },
					{ data: [0, 0, 0, 0], name: '清醒时间' },
				],
				[
					{ data: [2, 4, 4, 6, 2, 2, 4, 6], name: '深睡时间' },
					{ data: [2, 2, 2, 4, 6, 3, 5, 2], name: '浅睡时间' },
					{ data: [1, 2, 3, 2, 2, 4, 6, 2], name: '快速眼动' },
					{ data: [2, 2, 4, 6, 1, 0, 5, 2], name: '清醒时间' },
				],
				[
					{ data: [2, 0, 4, 6, 2, 0, 4, 6, 2, 0, 4, 6], name: '深睡时间' },
					{ data: [2, 3, 2, 2, 0, 3, 0, 0, 2, 3, 2, 2], name: '浅睡时间' },
					{ data: [0, 2, 2, 2, 4, 6, 0.5, 0, 2, 2, 4, 6], name: '快速眼动' },
					{ data: [0, 2, 2, 4, 6, 4, 1, 1, 2, 2, 4, 6], name: '清醒时间' },
				],
			],
		};
	},
	methods: {
		getSeries(data) {
			if (!data) return [];
			return data.map((s) => {
				s.stack = 'total';
				s.type = 'bar';
				s.barMaxWidth = 50;
				return s;
			});
		},
	},
};
</script>
